import React from 'react'
import Img from 'gatsby-image'
import { string, number } from 'prop-types'

const propTypes = {
  src: string.isRequired,
  sizes: string,
  aspectRatio: number,
}

const defaultProps = {
  sizes: '(max-width: 300px) 100vw, 300px',
  aspectRatio: 0.6,
}

const LazyImage = ({ src, sizes, aspectRatio, ...others }) => {
  const fluidObject = {
    src,
    sizes,
    aspectRatio,
    srcSet: src,
  }

  return <Img fluid={fluidObject} {...others} lazy="loading" />
}

LazyImage.propTypes = propTypes
LazyImage.defaultProps = defaultProps

export { LazyImage }
